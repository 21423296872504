<template>
  <v-container fluid>
    <v-card elevation="2">
      <v-card-text>
        <h1><v-icon class="iconTitle">mdi-table-account</v-icon> Liquidaciones y anticipos</h1><br>
        <v-layout wrap>
          <v-flex xs11>
            <v-autocomplete
              v-model="selectClient"
              :items="this.$store.state.productor"
              item-text="nombre"
              item-value="id"
              flat
              hide-no-data
              label="Buscar productor"
              prepend-inner-icon="mdi-account-search"
              solo-inverted
              @change="read(); getCampanas();"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs1>
            <v-btn v-on:click="clear" text class="btnMini">
              <v-icon large>mdi-plus</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs3>
            <v-select
              label="Producto a liquidar"
              v-model="producto"
              outlined
              :items="productos"
              item-text="n"
              item-value="v"
              @change="loadCategory(); getCampanas();"
            ></v-select>
          </v-flex>
          <v-flex xs3>
            <v-select
              label="Calidad / categoría"
              outlined
              v-model="calidad"
              :items="calidades"
              item-text="nombre"
              item-value="nombre"
            ></v-select>
          </v-flex>
          <v-flex xs2>
            <v-select
              label="Tipo de liquidacion"
              v-model="tipoliquidacion"
              outlined
              :items="tiposliquidacion"
              item-text="name"
              item-value="value"
            ></v-select>
          </v-flex>
          <v-flex xs2>
            <v-menu
              v-model="menuDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  autocomplete="off"
                  v-model="dateFormatted"
                  label="Fecha de liquidación"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                @change="changeDateFormatted('fechaLiquidacion');"
                locale="es"
                :first-day-of-week="1"
                v-model="dateInput"
                outlined
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs2>
            <v-select
              label="Campaña"
              outlined
              v-model="campana"
              :items="campanas"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs2>
            <v-text-field
              label="Kilos"
              outlined
              type="number"
              min="0"
              step="0.5"
              v-model="kilos"
              @change="calculaTotal"
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              label="€ / Kg"
              outlined
              type="number"
              min="0"
              step="0.5"
              v-model="precio"
              @change="calculaTotal"
            ></v-text-field>
          </v-flex>
          <v-flex xs1>
            <v-text-field
              label="Escandallo"
              outlined
              type="number"
              min="0"
              step="0.5"
              v-model="escandallo"
              @change="calculaTotal"
            ></v-text-field>
          </v-flex>
          <v-flex xs1>
            <v-select
              label=""
              v-model="tipoescandallo"
              outlined
              :items="tiposescandallo"
              item-text="name"
              item-value="value"
              @change="calculaTotal"
            ></v-select>
          </v-flex>
          <v-flex xs1>
            <v-text-field
              label="IVA"
              outlined
              type="number"
              min="0"
              step="0.5"
              v-model="iva"
              @change="calculaTotal"
            ></v-text-field>
          </v-flex>
          <v-flex xs1>
            <v-text-field
              label="IRPF"
              outlined
              type="number"
              min="0"
              step="0.5"
              v-model="irpf"
              @change="calculaTotal"
            ></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs2>
            <v-text-field
              label="Total"
              outlined
              readonly
              type="number"
              min="0"
              step="0.5"
              v-model="total"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn class="btnCustom save" @click="save"><v-icon class="btnIcon">mdi-content-save</v-icon> Guardar</v-btn>
      </v-card-actions>
    </v-card>
    <v-layout style="margin-top: 20px">
      <v-flex xs12>
        <v-data-table
          :headers="headersResumen"
          :items="resumen"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editarLiquidacion(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="eliminarLiquidacion(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <showDialog ref="dialog" />
    <showMessage ref="snackbar" />
  </v-container>
</template>

<script>
import {BASE_URL} from '../components/constants.js'
import * as basics from '../components/basics.js'
import showMessage from '../components/Messages.vue'
import showDialog from '../components/Dialog.vue'
import genericReload from '../components/genericReload';
export default {
  name: 'Producto',
  data () {
    return {
      rules: {
        required: [val => (val || '').length > 0 || 'Este campo es obligatorio'],
      },
      selectClient: null,
      producto: null,
      productos: [{n: 'Aceituna', v: 'Aceituna'},{n: 'Frutos secos', v: 'Cascara'},{n: 'Frutales', v: 'Frutales'}],
      calidades: [],
      calidad: null,
      tipoliquidacion: 'K',
      tiposliquidacion: [{name:'Liquidación por kilos', value:'K'}, {name:'Anticipo de campaña', value:'A'}],
      tipoescandallo: "euro",
      tiposescandallo: [{name:'€', value:'euro'},{name:'%', value:'porcentaje'}],
      menuDate: '',
      dateFormatted: '',
      dateInput: '',
      campana: null,
      campanas: [],
      kilos: '',
      precio: '',
      escandallo: '',
      iva: '',
      irpf: '',
      total: '',
      headersResumen: [
        {
          text: 'Fecha',
          align: 'start',
          sortable: false,
          value: 'fecha',
        },
        { text: 'Campaña', value: 'campana' },
        { text: 'Producto', value: 'valorproducto' },
        { text: 'Calidad', value: 'calidad' },
        { text: 'Tipo', value: 'valortipo' },
        { text: 'Kilos', value: 'kilos' },
        { text: 'Precio €/kg', value: 'precio' },
        { text: 'Escandallo', value: 'escandallo' },
        { text: 'IVA', value: 'iva' },
        { text: 'IRPF', value: 'irpf' },
        { text: 'Total', value: 'total' },
        { text: '', value: 'actions', sortable: false },
      ],
      resumen: [],
    }
  },
  components: {
    showMessage,
    showDialog,
  },
  mounted(){
    this.dateInput = basics.getDate();
    this.dateFormatted = basics.formatDate(basics.getDate(),"dd/mm/yyyy");
  },
  methods:{
    changeDateFormatted(field){
      switch(field){
        case "fechaLiquidacion":
          if(this.dateInput){
            this.dateFormatted = basics.formatDate(this.dateInput,"dd/mm/yyyy");
          }
          break;
      }
    },
    clear(){
      this.selectClient = null;
      this.producto = 'Aceituna';
      this.calidad = null;
      this.tipoliquidacion = 'K';
      this.tipoescandallo = "euro";
      this.menuDate = '';
      this.dateFormatted = '';
      this.dateInput = '';
      this.campana = null;
      this.campanas = [];
      this.kilos = '';
      this.precio = '';
      this.escandallo = '';
      this.iva = '';
      this.irpf = '';
      this.total = '';
      this.resumen = [];
    },
    async save(){
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.$store.state.auth
      };
      this.loading = true;
      if(this.selectClient != null && this.calidad != null && this.campana != null && this.kilos != '' && this.precio != '' ){
        const data = {
          idProductor: this.selectClient,
          fecha: this.dateInput,
          campana: this.campana,
          producto: this.producto,
          calidad: this.calidad,
          tipo: this.tipoliquidacion,
          mostrar: (this.tipoliquidacion == "K") ? 0 : 1,
          kilos: this.kilos,
          precioKilo: this.precio,
          escandallo: this.escandallo,
          coop: 1,
          tipoescandallo: this.tipoescandallo,
          iva: this.iva,
          irpf: this.irpf,
          estado: 'aceptado'
        };
        const response = await fetch(
          `${BASE_URL}/liquidacion`,
          {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
          }
        );
        switch (response.status) {
          case 200:
            this.$refs.snackbar.setNew(true, "Datos guardados correctamente", "Ok", "green");
            this.clear();
            this.read();
            this.loading = false;
            break;
          default:
            this.$refs.snackbar.setNew(true, "ERROR. Datos no guardados", "Cerrar", "red");
            this.loading = false;
            break;
        }
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. Faltan datos importantes para llevar a cabo la liquidación", "Cerrar", "red");
        this.loading = false;
      }
    },
    async read(){
      this.resumen = [];
      if(this.selectClient){
        const response = await genericReload(this.$store.state.auth, `liquidacion/?idProductor=${this.selectClient}&estado=aceptado`);
        if (response.status === 200) {
          const json = await response.json();
          for(let i = 0; i<json.body.length; i++){
            let total = json.body[i].kilos * json.body[i].precioKilo;
            if(json.body[i].tipoescandallo=="euro"){
              if(json.body[i].escandallo!=""){
                total = parseFloat(total) - parseFloat(json.body[i].escandallo);
              }
            }else{
              if(json.body[i].escandallo!=""){
                total = total - ((total * parseFloat(json.body[i].escandallo))/100);
              }
            }
            if(json.body[i].iva!=""){
              total = total + ((total * parseFloat(json.body[i].iva))/100);
            }
            if(json.body[i].irpf!=""){
              total = total - ((total * parseFloat(json.body[i].irpf))/100);
            }
            total = Math.round(total * 100) / 100;
            let tipo = (json.body[i].tipo == "K") ? "Liquidación" : "Anticipo"
            let producto = (json.body[i].producto == "Cascara") ? "Frutos secos" : json.body[i].producto
            this.resumen.push({
              id: json.body[i].id, 
              fecha: basics.formatDate(json.body[i].fecha,"dd/mm/yyyy"), 
              valorproducto: producto,
              producto: json.body[i].producto,
              calidad: json.body[i].calidad,
              tipo: json.body[i].tipo,
              valortipo: tipo,
              kilos: json.body[i].kilos,
              precio: json.body[i].precioKilo,
              campana: json.body[i].campana,
              valorescandallo: json.body[i].escandallo,
              escandallo: (json.body[i].tipoescandallo == "euro") ? json.body[i].escandallo+"€" : json.body[i].escandallo+"%",
              tipoescandallo: json.body[i].tipoescandallo,
              iva: json.body[i].iva,
              irpf: json.body[i].irpf,
              total: total,
            });
          }
        }else{
          this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar los datos de liquidaciones y anticipos anteriores", "Cerrar", "red");
        }
      }
    },
    async editarLiquidacion(item){
      this.$refs.dialog.setNew("Editar liquidación o anticipo", "", "editLiquidation", item).then(async(confirm) => {
        if(confirm.response){
          const data = {
            idProductor: this.selectClient,
            fecha: basics.formatDate(item.fecha,"yyyy-mm-dd", "/"),
            campana: item.campana,
            calidad: item.calidad,
            producto: item.producto,
            kilos: confirm.kilos,
            precioKilo: confirm.precio,
            escandallo: confirm.escandallo,
            tipoescandallo: confirm.tipoescandallo,
            iva: confirm.iva,
            irpf: confirm.irpf,
            estado: "aceptado",
            coop: 1,
            tipo: item.tipo,
            mostrar: (item.tipo == "K") ? 0 : 1,
          };
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+this.$store.state.auth
          };

          const response = await fetch(
            `${BASE_URL}/liquidacion/${item.id}`,
            {
              method: 'PUT',
              headers: headers,
              body: JSON.stringify(data),
            }
          );
          switch (response.status) {
            case 200:
              this.$refs.snackbar.setNew(true, "Entrada editada correctamente", "Ok", "green");
              this.read();
              break;
            default:
              this.$refs.snackbar.setNew(true, "ERROR. Entrada no editada", "Cerrar", "red");
              break;
          }
        }
      })
    },
    eliminarLiquidacion(item){
      this.$refs.dialog.setNew("Eliminar liquidación o anticipo", "¿Estás seguro de querer eliminar esta entrada?", 0).then(async(confirm) => {
        if(confirm.response){
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+this.$store.state.auth
          };

          const data = {
            idProductor: this.selectClient
          };

          const response = await fetch(
            `${BASE_URL}/liquidacion/${item.id}`,
            {
              method: 'DELETE',
              headers: headers,
              body: JSON.stringify(data),
            }
          );
          switch (response.status) {
            case 200:
              this.$refs.snackbar.setNew(true, "Entrada eliminada correctamente", "Ok", "green");
              this.read();
              break;
            default:
              this.$refs.snackbar.setNew(true, "ERROR. Entrada no eliminada", "Cerrar", "red");
              break;
          }
        }
      });
    },
    async getCampanas(){
      this.campanas = [];
      if(this.selectClient){
        const response = await genericReload(this.$store.state.auth, `entrada/?idProductor=${this.selectClient}&tipo=${this.producto}`);
        let arrayCampanas = []
        if (response.status === 200) {
          const json = await response.json();
          if(json.body.length > 0){
            for(let i = 0; i<json.body.length; i++){
              arrayCampanas.push(json.body[i].fecha);
            }
            arrayCampanas.sort(function(a,b){ return new Date(a) - new Date(b); });
            const anoInicio = parseInt(arrayCampanas[0].split("-")[0]);
            const anoFin = parseInt((new Date).getFullYear());
            const numCampanas= anoFin - anoInicio + 1;
            let i = 0;
            while(i<=numCampanas){
              this.campanas.push((anoInicio-1+i)+"/"+(anoInicio+i));
              i++;
            }
          }
        }else{
          this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar las campañas en las que este productor tiene entradas de producto", "Cerrar", "red");
        }
      }
    },
    async loadCategory(){
      this.calidades = [];
      const response = await genericReload(this.$store.state.auth, `categoria`);
      if (response.status === 200) {
        const json = await response.json();
        this.calidades = JSON.parse(JSON.stringify(json.body.filter((product) => product.producto === this.producto)));
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar las calidades / categorías para el producto seleccionado", "Cerrar", "red");
      }
    },
    calculaTotal(){
      this.total = this.kilos * this.precio;
      if(this.tipoescandallo=="euro"){
        if(this.escandallo!=""){
          this.total = parseFloat(this.total) - parseFloat(this.escandallo);
        }
      }else{
        if(this.escandallo!=""){
          this.total = this.total - ((this.total * parseFloat(this.escandallo))/100);
        }
      }
      if(this.iva!=""){
        this.total = this.total + ((this.total * parseFloat(this.iva))/100);
      }
      if(this.irpf!=""){
        this.total = this.total - ((this.total * parseFloat(this.irpf))/100);
      }
      this.total = Math.round(this.total * 100) / 100;
    },
  }
}
</script>
<style scoped>
.v-input{
  margin-right: 1em;
}
</style>